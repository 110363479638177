<template>
    <div class="mt-3 stage-inner-container-padded">
        <b-row> 
            <b-col xl="4" lg="6" md="12" v-for="(key, index) in Object.keys(obj)" :key="index"> <!-- Events -->
                <b-row>
                    <b-col cols="12"><h4>{{key}}</h4></b-col>
                    <b-col cols="12">
                        <b-form-checkbox
                            class="ml-4"
                            :id="`${key}-access`"
                            v-model="obj[key].access"
                            :name="`${key}-access`"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Access Page {{key}}
                        </b-form-checkbox>
                    </b-col>
                    <div v-if="obj[key].actions != undefined">
                        <b-col cols="12"  v-for="(action, actionIndex) in Object.keys(obj[key].actions)" :key="`${key}-action-${actionIndex}`">
                            <b-form-checkbox
                                class="ml-4"
                                :id="`${key}-${action}`"
                                v-model="obj[key].actions[action]"
                                :name="`${key}-${action}`"
                                :value="true"
                                :unchecked-value="false"
                            >
                                {{action}}
                            </b-form-checkbox>
                        </b-col>
                    </div>
                    
                </b-row>
            </b-col>
        </b-row>
        <div v-if="showResults">
            <b-button @click="generateEndpointJSON">Create UI Control JSON</b-button>
            <b-button @click="setAsOwnUIPC">Set As Current Login UIEPC</b-button>
            <!-- <b-button @click="setAllTrue">Grant All Permissions</b-button> -->
            <b-form-textarea
                id="textarea"
                v-model="json"
                rows="3"
                max-rows="500"
            ></b-form-textarea>
        </div>
        <div v-else class="d-flex flex-row-reverse">
            <b-button variant="success" @click="generateEndpointJSON">Save UI Control Permissions</b-button>
        </div>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = require('underscore');

export default {
    name: 'uipermissionsbuilder',
    components:{

    },
    props:{
        grantAdmin:{
            type: Boolean,
            default: () => { return false }
        },
        showResults:{
            type: Boolean,
            default: () => { return false }
        },
        onCompleted:{
            type: Function,
            default: (val) => {
                console.log(`EPC No OnCompleted Callback Prop Provided JSON: `)
                console.log(val);
            }
        }
    },
    data(){
        return{
            json: null,
            choiceListKeys: ["connection_types", "grade_types", "hazard_levels", "hazard_types", "protection_types", "service_types", "site_types", "device_types", "device_manufacturers", "group_names", "product_codes", "notice_types"],
            choiceListNames: ["Connection Types","Grade Types","Hazard Levels","Hazard Types","Protection Types","Service Types","Site Types","Device Types","Device Manufacturers","Group Names","Product Codes","Notice Types"],
            choiceListNameSingular: ["Connection Type","Grade Type","Hazard Level","Hazard Type","Protection Type","Service Type","Site Type","Device Type","Device Manufacturer","Group Name","Product Code","Notice Type"],
            obj: {
                dashboard:{
                    access: true,
                    actions:{
                        createCustomer: true,
                        createSite: true,
                        createConnection: true,
                        createDevice: true
                    }
                },
                choiceLists:{
                    access: true,
                    actions: {
                        update: true,
                        create: true
                    }
                },
                search:{
                    access: true
                },
                viewTest: {
                    access: true,
                    actions:{
                        emailReport: true
                    }
                },
                viewSurvey:{
                    access: true,
                },
                viewReplacementInstall:{
                    access: true,
                },
                viewTaskComment:{
                    access: true,
                },
                utilities:{
                    access: true,
                    actions: {
                        update: true,
                        create: true
                    }
                },
                technicians:{
                    access: true,
                    actions: {
                        update: true,
                        create: true
                    }
                },
                deviceModels:{
                    access: true,
                    actions: {
                        update: true,
                        create: true
                    }
                },
                eventList:{
                    access: true,
                    actions: {
                        addTest: true,
                        replaceInstallDevice: true,
                        issueToFulcrum: true,
                        downloadPDF: true
                    }
                },
                form:{
                    access: true,
                    actions: {
                        addFlag: true,
                        removeFlag: true
                    }
                },
                fulcrumBulkIssue:{
                    access: true,
                },
                bulkGeocode:{
                    access: true,
                },
                closeouts:{
                    access: true,
                    actions:{
                        allowClose: true,
                        allowDownload: true,
                        allowSend: true,
                        allowBill: true
                    }
                },
                listBuilder:{
                    access: true,
                    actions:{
                        canSave: true,
                        canDownloadCSV: true,
                        canPivotTable: true,
                    }
                },
                bulk: {
                    access: false,
                    actions: {
                        list: false,
                        perform: false,
                    }
                },
                letterBuilder:{
                    access: true,
                },
                emailBuilder:{
                    access: true,
                },
                letterBatchManager:{
                    access: true,
                },
                formBuilder:{
                    access: false,
                },
                formAssignment:{
                    access: false,
                },
                accountManagement:{
                    access: false,
                    actions:{
                        viewBilling: false,
                        manageAccountUsers: false,
                        setPortalAutomationRules: false
                    }
                }
            },
            setupCompleted: null
        }
    },
    methods:{
        getJSONOnly(){
            return JSON.stringify(this.obj);
        },
        generateEndpointJSON(){
            this.json = JSON.stringify(this.obj);
            this.onCompleted(this.json);
        },
        setAllFalse(){
            var topLevelKeys = _.keys(this.obj);
            topLevelKeys.forEach(key=>{
                var endpointKeys = _.keys(this.obj[key]);
                endpointKeys.forEach(endpoint=>{
                    var actionKeys = _.keys(this.obj[key][endpoint]);
                    actionKeys.forEach((actionKey)=>{
                        this.obj[key][endpoint][actionKey] = false;
                    })
                })
            })
        },
        setAllTrue(){
            var topLevelKeys = _.keys(this.obj);
            topLevelKeys.forEach(key=>{
                var endpointKeys = _.keys(this.obj[key]);
                endpointKeys.forEach(endpoint=>{
                    var actionKeys = _.keys(this.obj[key][endpoint]);
                    actionKeys.forEach((actionKey)=>{
                        this.obj[key][endpoint][actionKey] = true;
                    })
                })
            })
        },
        setAsOwnUIPC(){
            console.log("setting own UIPC")
            this.$store.commit('setUIControl',{ uipc: JSON.parse(JSON.stringify(this.obj)) });
        },
        performSetup(){
            this.setupCompleted = new Promise((resolve, reject)=>{
                // Check if this account has a fulcrum link
                butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/atils/has_fulcrum_link.json`)
                .then((response)=>{
                    // console.log(`Has Fulcrum Link: ${response.data.result.hasFulcrumLink}`)
                    if(!response.data.result.hasFulcrumLink){
                        // Revoke Closeouts
                        this.obj.closeouts.access = false;
                        this.obj.closeouts.actions = _.mapObject(this.obj.closeouts.actions, (val, key)=>{
                            return false;
                        })
                        // Revoke Fulcrum
                        this.obj.fulcrumBulkIssue.access = false;
                        this.obj.eventList.actions.issueToFulcrum = false;
                    }else{

                    }
                    resolve();
                    this.generateEndpointJSON();
                })
                .catch((err)=>{
                    console.log(err);
                    reject(err);
                })
            })
            
            
            // Forms
            this.obj.formAssignment.access = this.grantAdmin;
            this.obj.formBuilder.access = this.grantAdmin;
            // Bulk Record Management
            this.obj.bulk.access = this.grantAdmin;
            this.obj.bulk.actions.list = this.grantAdmin;
            this.obj.bulk.actions.perform = this.grantAdmin;
            // Account Management
            this.obj.accountManagement.access = this.grantAdmin;
            this.obj.accountManagement.actions = _.mapObject(this.obj.accountManagement.actions, (val, key)=>{
                return this.grantAdmin;
            })
        }
    },
    watch:{
        grantAdmin(newVal, oldVal){
            this.performSetup();
        }
    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount(){
    },
    mounted(){
        this.performSetup();
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>