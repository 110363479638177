import { render, staticRenderFns } from "./UIPermissionsBuilder.vue?vue&type=template&id=0b30f244&scoped=true&"
import script from "./UIPermissionsBuilder.vue?vue&type=script&lang=js&"
export * from "./UIPermissionsBuilder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b30f244",
  null
  
)

export default component.exports